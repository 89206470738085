/* eslint-disable no-unused-vars */
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { RiGithubFill, RiQuestionLine } from "react-icons/ri";
import {
  ColorModeSwitcher,
  Nav,
  NavLink,
  NavList,
  NavListItem,
} from "smooth-doc/components";

const AppNavCustomQuery = graphql`
  query AppNavCustom {
    site {
      siteMetadata {
        githubRepositoryURL
      }
    }
  }
`;

export const AppNav = () => {
  const data = useStaticQuery(AppNavCustomQuery);
  return (
    <Nav>
      <NavList>
        <NavListItem>
          <NavLink to="/docs/">Docs</NavLink>
        </NavListItem>
        <NavListItem>
          <NavLink
            to="https://sirius-support.zendesk.com/hc/fr/requests/new"
            target="_blank"
            rel="noopener noreferrer"
            title="Support"
          >
            <RiQuestionLine size={24} />
          </NavLink>
        </NavListItem>
        <NavListItem>
          <NavLink
            as="a"
            href={data.site.siteMetadata.githubRepositoryURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiGithubFill size={24} />
          </NavLink>
        </NavListItem>
        <NavListItem>
          <NavLink as={ColorModeSwitcher} />
        </NavListItem>
      </NavList>
    </Nav>
  );
};
