import { Link } from "gatsby";
import { Button, Hero, HeroAction, HeroActionList, HeroBody, HeroSection, HeroTeaser, HeroTitle, ScreenContainer } from "smooth-doc/components";
import heroBackgroundImageURL from "../../../../images/hero-background.png";
import * as React from 'react';
export default {
  Link,
  Button,
  Hero,
  HeroAction,
  HeroActionList,
  HeroBody,
  HeroSection,
  HeroTeaser,
  HeroTitle,
  ScreenContainer,
  heroBackgroundImageURL,
  React
};