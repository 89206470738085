import { theme as baseTheme } from "smooth-doc/src/theme";

export const theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    "primary-50": "#f2fafb",
    "primary-100": "#dcf7fa",
    "primary-200": "#b0edf4",
    "primary-300": "#7adff0",
    "primary-400": "#36c3ea",
    "primary-500": "#14a0e1",
    "primary-600": "#0f7ece",
    "primary-700": "#1364a9",
    "primary-800": "#144d7c",
    "primary-900": "#123e60",
  },
};
